import React from 'react'

const English = () => {
    return (
    <div className='w-11/12 lg:w-10/12 max-w-[1600px] mx-auto mt-10'>
        <h1 className='text-xl font-bold text-center mb-5'>
            Cookie Policy
        </h1>

        <p className="mb-5">
        This policy provides information regarding the use of cookies and other similar technologies on the website URL (hereinafter referred to as the "<strong>Website</strong>"). This notice is provided to the user in compliance with European and Italian legislation on the processing of personal data and electronic communications.
        </p>
        <p className="mb-5">
        The Website is owned by Chiesi Farmaceutici S.p.A., with registered offices in Via Palermo 26/A, 43122 Parma (hereinafter referred to as "<strong>Chiesi</strong>" or "<strong>Controller</strong>"), which you may want to contact for any information or request regarding the use of cookies and other similar technologies on this Website.
        </p>
        <p className="mb-5">
        The Controller designated a Data Protection Officer (DPO), who can be directly contacted at the following contact details: Data Protection Officer - DPO c/o Chiesi Farmaceutici S.p.A., Via Palermo 26/A, 43122 – Parma (PR), Italia, email: <a href="mailto:dpoit@chiesi.com" className='underline text-blue-500'>dpoit@chiesi.com</a>. 
        </p>
        <p className="mb-5">
        For further information on the processing of personal data, please read the Privacy Policy of the Website.
        </p>

        <h2 className='font-bold text-lg mb-5'>
            WHAT ARE COOKIES?
        </h2>
        <p className="mb-5">
        Cookies are text files stored on your computer or mobile device when you use the Website, enabling a faster and more enjoyable online experience. 
        </p>
        <p className="mb-5">
        Cookies are downloaded from your Internet browser and stored on your device the first time you visit a website. The next time you visit the same website using the same device, the website will recognize that you have seen the website before and adjust the content considering your previous visits. This way, the website experience can be more faithful to your interests and preferences.
        </p>
        <p className="mb-5">
        Chiesi also uses other technologies like cookies to share the information we collect when you visit the Website. Please also note that Chiesi may include technologies (including but not limited to web beacons and pixel tags) for advertising purposes or to monitor your interaction with the Website.
        </p>
        
        <p className="mb-5 underline">
        Web beacon, pixel tag, and embedded scripts
        </p>
        <p className="mb-5">
        A web beacon is an electronic graphic image embedded in a website or email to identify cookies when you browse the website or read the email. Pixel tags allow sending readable emails to users and finding out whether they have opened the email. 
        </p>
        <p className="mb-5">
        For example, if you receive an email from Chiesi, the email may contain a login URL that links to our web page. If you click on the link, Chiesi will keep track of your visit to learn about your preferences.
        </p>
        <p className="mb-5">
        Embedded scripts are programming codes of some web pages that measure how you use them, for example, the links you select. This information is used to improve Chiesi-owned websites and online services, to tailor them to your likely interests, and to conduct market research. Users can disable scripting features, such as JavaScript, within the browser settings (see the help feature of your browser). Please note that if you disable scripting functionality, some Chiesi websites and applications may not function properly.
        </p>

        <p className="mb-5 underline">
        Proprietary and Third-Party Cookies
        </p>
        <p className="mb-5">
        Proprietary cookies are those implemented by Chiesi and used to interact with you when you browse the Website.
        </p>
        <p className="mb-5">
        The Website may also offer content from other websites that integrate their cookies (third-party cookies). Third parties provide these cookies. These providers may set their cookies while you are visiting the Website and receive information about your behavior while navigating the Website. Therefore, you should see the websites of these third parties to learn more about how they use these cookies. Please see the table at the end of this policy for more information about which third-party cookies are used on the Website.
        </p>
        
        <h2 className='font-bold text-lg mb-5'>
        HOW TO CONTROL COOKIES AND SIMILAR TECHNOLOGIES
        </h2>
        <p className="mb-5">
        Chiesi provides tools to facilitate the management of proprietary and partner cookies.
        </p>
        <p className="mb-5">
        If you decline cookies that require your prior consent, Chiesi will not use those cookies. If you decide to revoke your previous consent, Chiesi will no longer use those cookies and delete the relevant proprietary ones. However, please note that if you refuse and cancel functional or performance cookies, you may not be able to use the full functionality of the Website, and Chiesi may no longer understand what you like and dislike about the Website and improve it.
        </p>
        <p className="mb-5">
        For clarity, be mindful that refusing or withdrawing your consent to targeted advertising cookies does not mean you will receive less promotional advertising. See the next section for more information on profiling cookies.
        </p>
        <p className="mb-5 underline">
        Browser controls to cancel cookies
        </p>
        <p className="mb-5">
        You can set your browser through the dedicated options to automatically refuse the installation of cookies or delete all installed cookies from your computer or mobile device.
        </p>
        <p className="mb-5">
        The procedure differs depending on the browser in use. Below you can find the links to the support pages of the main browsers:
        </p>

        <div className='mb-5 flex flex-wrap'>
            <a
                target="_blank"
                href={"https://support.google.com/accounts/topic/2373959?hl=it&ref_topic=2373957"}
                className='mr-3 text-blue-500 underline'
            >
                Google Chrome
            </a>
            <p className='mr-3'>
                {">"}
            </p>
            <a
                target="_blank"
                href={"https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie#firefox:win7:fx38"}
                className='mr-3 text-blue-500 underline'
            >
                Mozilla Firefox
            </a>
            <p className='mr-3'>
                {">"}
            </p>
            <a
                target="_blank"
                href={"http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11-win-7"}
                className='mr-3 text-blue-500 underline'
            >
                Internet Explorer
            </a>
            <p className='mr-3'>
                {">"}
            </p>
            <a
                target="_blank"
                href={"https://support.apple.com/it-it/HT201265"}
                className='mr-3 text-blue-500 underline'
            >
                Safari 
            </a>
            <p className='mr-3'>
                {">"}
            </p>
            <a
                target="_blank"
                href={"https://support.apple.com/it-it/HT201265"}
                className='mr-3 text-blue-500 underline'
            >
                Safari su iPhone, iPad, o iPod touch 
            </a>
        </div>

        <p className="mb-5">
        Keep always in mind that disabling and deleting technical cookies could lead to malfunctions in the navigation of the Website.
        </p>


        <h2 className='font-bold text-lg mb-5'>
        CATEGORIES OF COOKIES
        </h2>
        <p className="mb-5">
        There are different types of cookies, and each has a specific function that provides you with a better browsing experience in case you are using the same device and browser with which you visited the Website for the first time.
        </p>
        
        <p className="mb-5">
        <span className='underline'>Technical or strictly necessary cookies</span> - default activation
        </p>
        <p className="mb-5">
        This cookie category is necessary to use the Website and provide essential technical functionality. Using the Website without these cookies would not be possible, and they are generally installed by default. If you reject them, you might not be able to access the site. 
        </p>
        
        <p className="mb-5">
        <span className='underline'>Performance cookies</span> - default activation
        </p>
        <p className="mb-5">
        Chiesi uses performance cookies to understand user behavior to improve the performance of the Website and to provide a personalized experience. This type of cookie collects information about how you interact with the Website, allowing, for example, Chiesi to have an overview of the pages you visit and the time you spend on them. Please note that this information is collected in a fully aggregated manner and cannot be directly linked to you. 
        </p>
        
        <p className="mb-5">
        <span className='underline'>Profiling cookies (for targeted advertising) </span> - optional activation
        </p>
        <p className="mb-5">
        This cookie category delivers advertising and marketing communications in line with your interests. Profiling cookies track your visits to different websites and share your interests with third parties, such as advertisers or media companies. These operators may also create dedicated profiles to show relevant ads on other websites. 
        </p>

        <h2 className='font-bold text-lg mb-5'>
        LIST OF COOKIES IN USE
        </h2>
        <p className="mb-5">
        The following is a list of the cookies used by this Website with a description of their specific functioning:
        </p>

        <div className='mb-5 overflow-x-auto'>
            <div className='min-w-[700px]'>
                <div className='flex'>
                    <div className='text-center w-[20%] p-3 border-2 border-r-0 border-black'>
                        <p className='font-bold'>
                        COOKIE NAME
                        </p>
                    </div>
                    <div className='text-center w-[20%] p-3 border-2 border-r-0 border-black'>
                        <p className='font-bold'>
                        EXPIRY TIME 
                        </p>
                    </div>
                    <div className='text-center w-[60%] p-3 border-2 border-black'>
                        <p className='font-bold'>
                        COOKIE DESCRIPTION
                        </p>
                    </div>
                </div>
                
                <div className='flex'>
                    <div className='w-[20%] p-3 border-2 border-t-0 border-r-0 border-black'>
                        <p className=''>
                        _dlt
                        </p>
                    </div>
                    <div className='w-[20%] p-3 border-2 border-t-0 border-r-0 border-black'>
                        <p className=''>
                        Session End 
                        </p>
                    </div>
                    <div className='w-[60%] p-3 border-2 border-t-0 border-black'>
                        <p className=''>
                        Sets a unique ID for the session. This allows the website to obtain data about visitor behavior for statistical purposes
                        </p>
                    </div>
                </div>
                
                {/* <div className='flex'>
                    <div className='w-[20%] p-3 border-2 border-t-0 border-r-0 border-black'>
                        <p className=''>
                        CMPList
                        </p>
                    </div>
                    <div className='w-[20%] p-3 border-2 border-t-0 border-r-0 border-black'>
                        <p className=''>
                        1 year 
                        </p>
                    </div>
                    <div className='w-[60%] p-3 border-2 border-t-0 border-black'>
                        <p className=''>
                        Cookie to remember the user consents regarding cookies
                        </p>
                    </div>
                </div> */}

            </div>
        </div>

        <p className="mb-5">
        For more information about Cookies used on the Website and how to block or restrict them, please visit <a href={"https://www.aboutcookies.org/"} target="_blank" className='text-blue-500 underline'>www.aboutcookies.org</a>.   
        </p>
        <p className="mb-5">
        For general legal information about Chiesi and the use of the Website, please see the "Terms of Use".
        </p>
    </div>
    )
}

export default English